<template>
  <div class="profile-container">
    <div class="content-wrapper">
      <!-- Profile Header -->
      <div class="profile-header">
        <div class="profile-avatar-wrapper">
          <img class="profile-avatar" src="https://via.placeholder.com/150" alt="Profile Picture" />
        </div>
        <div class="profile-info">
          <h1 class="profile-name">Name</h1>
          <div class="profile-contact">
            <p class="profile-phone"><i class="fas fa-phone"></i>+91 9072121314</p>
          </div>
        </div>
      </div>

      <!-- Profile Tabs -->
      <div class="profile-tabs">
        <!-- Personal Details Dropdown -->
        <div class="dropdown">
          <button :class="{ active: activeTab === 'personal' || activeTab === 'address' }" @click="toggleDropdown">
            Details <i class="fas fa-chevron-down"></i>
          </button>
          <div v-if="isDropdownOpen" class="dropdown-menu">
            <button @click="switchTab('personal')">Personal Details</button>
            <button @click="switchTab('address')">Address Details</button>
          </div>
        </div>

        <!-- Dependencies Tab -->
        <button :class="{ active: activeTab === 'dependencies' }" @click="activeTab = 'dependencies'">
          Dependencies
        </button>
      </div>

      <!-- Profile Content -->
      <div class="profile-content">
        <!-- Personal Details Tab -->
        <div v-if="activeTab === 'personal'">
          <h2>Personal Details</h2>
          <div class="profile-details">
            <div class="detail-item"><label>Name</label><input type="text" placeholder="Shuhad Abdul Azeez" disabled />
            </div>
            <div class="detail-item"><label>Age</label><input type="text" placeholder="25" disabled /></div>
            <div class="detail-item"><label>Aadhaar</label><input type="text" placeholder="XXXX-XXXX-XXXX" disabled />
            </div>
            <div class="detail-item"><label>Mobile 1</label><input type="text" placeholder="+91 9072121314" disabled />
            </div>
            <div class="detail-item"><label>Mobile 2</label><input type="text" placeholder="+91 9876543210" disabled />
            </div>
            <div class="detail-item"><label>Job</label><input type="text" placeholder="Software Developer" disabled />
            </div>
            <div class="detail-item"><label>ലാൻഡ് മാർക്ക്</label><input type="text" placeholder="Near Bus Stop"
                disabled /></div>
            <div class="detail-item"><label>വിദ്യാഭ്യാസ യോഗ്യത</label><input type="text" placeholder="B.Tech"
                disabled /></div>
            <div class="detail-item"><label>വിവാഹിതൻ ആണോ</label><input type="text" placeholder="Yes" disabled /></div>
            <div class="detail-item"><label>തുണ ജീവിച്ചിരിപ്പുണ്ടോ</label><input type="text" placeholder="Yes"
                disabled /></div>
          </div>
        </div>


        <!-- Address Details Tab -->
        <div v-if="activeTab === 'address'">
          <h2>Address Details</h2>
          <div class="profile-details">
            <div class="detail-item"><label>House Name</label><input type="text" placeholder="House Name" disabled />
            </div>
            <div class="detail-item"><label>Residence Number</label><input type="text" placeholder="1234" disabled />
            </div>
            <div class="detail-item"><label>Electric Post Number</label><input type="text" placeholder="5678"
                disabled /></div>
            <div class="detail-item"><label>Ward Number</label><input type="text" placeholder="9" disabled /></div>
            <div class="detail-item"><label>Place</label><input type="text" placeholder="Kozhikode" disabled /></div>
            <div class="detail-item"><label>Post Office</label><input type="text" placeholder="Post Office Name"
                disabled /></div>
            <div class="detail-item"><label>Pin</label><input type="text" placeholder="673001" disabled /></div>
          </div>
        </div>

        <!-- Dependencies Tab -->
        <div v-if="activeTab === 'dependencies'">
          <h2>Dependent Details</h2>
          <a data-bs-toggle="collapse" href="#category-1" aria-expanded="false" aria-controls="category-1"
            class="no-effect mb-2">
            <div class="content">
              <div class="d-flex mb-n1">
                <div class="align-self-center">
                  <h1 class="pe-3 font-40 font-900 opacity-30">01</h1>
                </div>
                <div class="align-self-center">
                  <h4>Applicant Information</h4>
                  <p class="opacity-80 font-10 mt-n2">Tap here to Expand</p>
                </div>
                <div class="ms-auto align-self-center text-center opacity-70">
                  <i class="fa fa-angle-down color-gray-dark opacity-70 font-10 mt-1"></i><br>
                </div>
              </div>
            </div>
          </a>
          <div class="collapse" id="category-1">
            <!-- Recent Purchases -->
            <div class="card">
              <div class="content">
                <div class="list-group list-custom-large">
                  <a class="external-link" href="">
                    <i class="fa fa-user bg-user rounded-sm"></i>
                    <span  class="mb-n1 font-15">Name</span>
                    <strong  class="mb-n1 font-12">Abdullah</strong>
                  </a>
                  <a class="external-link" href="">
                    <i class="fa fa-calendar-alt rounded-sm"></i>
                    <span class="mb-n1 font-14">Age</span>
                    <strong class="mb-n1 font-12">40</strong>
                  </a>
                  <a href="#">
                    <i class="fa fa-briefcase bg-briefcase rounded-sm"></i>
                    <span class="mb-n1 font-14">Job</span>
                    <strong class="mb-n1 font-12">Business</strong>
                  </a>
                  <a href="#">
                    <i class="fa fa-graduation-cap bg-education rounded-sm"></i>
                    <span class="mb-n1 font-14">Education (Class)</span>
                    <strong class="mb-n1 font-12">Degree</strong>
                  </a>
                  <a href="#" class="border-0">
                    <i class="fa fa-money-bill-wave bg-income rounded-sm"></i>
                    <span class="mb-n1 font-14">Monthly Income</span>
                    <strong class="mb-n1 font-12">15000</strong>
                  </a>
                  <a href="#" class="border-0">
                    <i class="fa fa-users bg-relationship rounded-sm"></i>
                    <span class="mb-n1 font-14">Relationship with Applicant</span>
                    <strong class="mb-n1 font-12">Cousin</strong>
                  </a>
                  <a href="#" class="border-0">
                    <i class="fa fa-heart bg-marital-status rounded-sm"></i>
                    <span class="mb-n1 font-14">Marital Status</span>
                    <strong class="mb-n1 font-12">Single</strong>
                  </a>
                  <a href="#" class="border-0">
                    <i class="fa fa-house-user bg-living rounded-sm"></i>
                    <span  class="mb-n1 font-12">Living Together</span>
                    <strong class="mb-n1 font-12">No</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <a data-bs-toggle="collapse" href="#category-2" aria-expanded="false" aria-controls="category-2"
            class="no-effect mb-2">
            <div class="content">
              <div class="d-flex mb-n1">
                <div class="align-self-center">
                  <h1 class="pe-3 font-40 font-900 opacity-30">02</h1>
                </div>
                <div class="align-self-center">
                  <h4>Applicant Information</h4>
                  <p class="opacity-80 font-10 mt-n2">Tap here to Expand</p>
                </div>
                <div class="ms-auto align-self-center text-center opacity-70">
                  <i class="fa fa-angle-down color-gray-dark opacity-70 font-10 mt-1"></i><br>
                </div>
              </div>
            </div>
          </a>
          <div class="collapse" id="category-2">
            <!-- Recent Purchases -->
            <div class="card">
              <div class="content mb-4">
                <div class="list-group list-custom-large">
                  <a class="external-link" href="">
                    <i class="fa fa-user bg-user rounded-sm"></i>
                    <span>Name</span>
                    <strong>Abdullah</strong>
                  </a>
                  <a class="external-link" href="">
                    <i class="fa fa-calendar-alt rounded-sm"></i>
                    <span>Age</span>
                    <strong>40</strong>
                  </a>
                  <a href="#">
                    <i class="fa fa-briefcase bg-briefcase rounded-sm"></i>
                    <span>Job</span>
                    <strong>Business</strong>
                  </a>
                  <a href="#">
                    <i class="fa fa-graduation-cap bg-education rounded-sm"></i>
                    <span>Education (Class)</span>
                    <strong>Degree</strong>
                  </a>
                  <a href="#" class="border-0">
                    <i class="fa fa-money-bill-wave bg-income rounded-sm"></i>
                    <span>Monthly Income</span>
                    <strong>15000</strong>
                  </a>
                  <a href="#" class="border-0">
                    <i class="fa fa-users bg-relationship rounded-sm"></i>
                    <span>Relationship with Applicant</span>
                    <strong>Cousin</strong>
                  </a>
                  <a href="#" class="border-0">
                    <i class="fa fa-heart bg-marital-status rounded-sm"></i>
                    <span>Marital Status</span>
                    <strong>Single</strong>
                  </a>
                  <a href="#" class="border-0">
                    <i class="fa fa-house-user bg-living rounded-sm"></i>
                    <span>Living Together</span>
                    <strong>No</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer with Logout Button -->
    <div class="profile-footer">
      <button class="logout-button" @click="logout"><i class="fas fa-sign-out-alt"></i> Logout</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      activeTab: 'personal', // Default tab is Personal Details
      isDropdownOpen: false, // To handle dropdown visibility
    };
  },
  methods: {
    ...mapActions(['logout']), // Map the logout action from Vuex
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen; // Toggle dropdown
    },
    switchTab(tab) {
      this.activeTab = tab; // Switch to the selected tab
      this.isDropdownOpen = false; // Close dropdown after selection
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.profile-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding-bottom: 80px;
}

.content-wrapper {
  flex: 1;
}

.profile-header {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #ffffff 0%, #dcdcdc 100%);
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  gap: 20px;
}

.profile-avatar-wrapper {
  flex-shrink: 0;
}

.profile-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.profile-avatar:hover {
  transform: scale(1.05);
}

.profile-info {
  flex-grow: 1;
}

.profile-name {
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 5px;
}

.profile-contact {
  display: flex;
  flex-wrap: wrap;
}

.profile-phone,
.profile-email {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.profile-phone i,
.profile-email i {
  margin-right: 10px;
}

.profile-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.profile-tabs button {
  background-color: rgb(215, 215, 215);
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 16px;
}

.profile-tabs button.active {
  background-color: #2575fc;
  color: white;
}

.profile-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.profile-content h2 {
  margin-bottom: 30px;
  font-size: 24px;
  color: #333;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

.profile-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.detail-item {
  display: flex;
  flex-direction: column;
}

.detail-item label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #666;
  font-weight: 500;
}

.detail-item input {
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 16px;
  color: #333;
  transition: all 0.3s ease;
}

.detail-item input:disabled {
  background-color: #f3f3f3;
  cursor: not-allowed;
}

.detail-item input:focus {
  outline: none;
  border-color: #2575fc;
  box-shadow: 0 0 0 2px rgba(37, 117, 252, 0.1);
}

.edit-button {
  background-color: #2575fc;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}

.edit-button:hover {
  background-color: #1a68e6;
}

.dependencies-list {
  list-style-type: none;
  padding: 0;
}

.dependencies-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.dependencies-list li:last-child {
  border-bottom: none;
}

.dependency-name {
  font-weight: 500;
}

.dependency-version {
  color: #666;
}

.dependency-status {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

.dependency-status.up-to-date {
  background-color: #e6f4ea;
  color: #34a853;
}

.dependency-status.update-available {
  background-color: #fef6e0;
  color: #fbbc04;
}

.profile-footer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.logout-button {
  background: linear-gradient(45deg, #ff6b6b, #be1616);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.logout-button i {
  margin-right: 10px;
}

.logout-button:hover {
  background: linear-gradient(45deg, #d65353, #a61010);
}

/* Dropdown styles */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background-color: white;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: #f1f1f1;
}


@media (max-width: 768px) {
  .profile-header {
    flex-direction: row;
    align-items: center;
    padding: 15px;
  }

  .profile-container {
    padding-bottom: 100px;
  }

  .profile-avatar {
    width: 70px;
    height: 70px;
  }

  .profile-name {
    font-size: 20px;
  }

  .profile-contact {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-phone,
  .profile-email {
    font-size: 12px;
    margin-bottom: 3px;
  }

  .profile-tabs {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .profile-tabs button {
    font-size: 14px;
    padding: 8px 16px;
    margin: 0 10px 10px 0;
  }

  .profile-content {
    padding: 20px 15px;
  }

  .profile-details {
    grid-template-columns: 1fr;
  }

  .profile-info {
    line-height: 0px;
  }
}

@media (max-width: 480px) {
  .profile-header {
    padding: 10px;
  }

  .profile-avatar {
    width: 60px;
    height: 60px;
  }

  .profile-name {
    font-size: 18px;
  }

  .profile-contact {
    font-size: 11px;
  }

  .profile-tabs button {
    flex: 1 0 40%;
    font-size: 12px;
    padding: 6px 12px;
  }

  .profile-content {
    padding: 15px 10px;
  }

  .profile-info {
    line-height: 0px;
  }
}

@media (max-width: 360px) {
  .profile-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-avatar-wrapper {
    margin: 0 0 10px 0;
  }

  .profile-info {
    width: 100%;
  }

  .profile-contact {
    justify-content: center;
  }

  .profile-info {
    line-height: 0px;
  }
}
</style>
