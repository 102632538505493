<template>

    <div id="page">

        <div class="page-content header-clear-small">

            <div class="card card-style">
                <div class="bannerAbout">
                    <div class="banner-content">
                        <h2 style="font-size: 30px;">About JCC</h2>
                    </div>
                </div>
            </div>

            <div class="card card-style">
                <div class="content">
                    <h6 class="color-highlight text-uppercase font-700 mb-1">Lives Impacted</h6>
                    <h1 class="text-uppercase font-900 mt-n2">HUNDREDS OF THOUSANDS</h1>
                    <p>
                        We've helped over 300,000 people in need. We're grateful for every life we've touched!
                    </p>

                    <div class="divider"></div>

                    <div class="row text-center">
                        <div class="col-6">
                            <i class="fa fa-hand-holding-heart fa-4x color-theme mb-3"></i>
                            <h6 class="text-uppercase color-highlight font-700">Donations & <br> Aid</h6>
                            <p>
                                We've distributed over 1 million meals to those in need.
                            </p>
                        </div>
                        <div class="col-6">
                            <i class="fa fa-school fa-4x color-theme mb-3"></i>
                            <h6 class="text-uppercase color-highlight font-700">Education & <br> Empowerment</h6>
                            <p>
                                We've supported education for 50,000 children worldwide.
                            </p>
                        </div>
                    </div>

                    <div class="divider"></div>

                    <h6 class="color-highlight text-uppercase font-700 mb-1 font-15">OVER 10,000 VOLUNTEERS</h6>
                    <h1 class="text-uppercase font-900 mt-n2">Dedicated Team</h1>
                    <p>
                        Our passionate volunteers are the backbone of our organization. Their commitment drives our
                        mission forward.
                    </p>

                    <div class="row mb-3">
                        <div class="col-3 text-center">
                            <a href="#" class="icon icon-xxl rounded-l bg-yellow-dark"><i
                                    class="fa fa-globe font-24"></i></a>
                        </div>
                        <div class="col-9">
                            <h5 class="text-uppercase font-800 mb-0">Global Reach</h5>
                            <p>
                                We operate in 50 countries, bringing hope and support to communities worldwide.
                            </p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-3 text-center">
                            <a href="#" class="icon icon-xxl rounded-l bg-green-dark"><i
                                    class="fa fa-leaf font-24"></i></a>
                        </div>
                        <div class="col-9">
                            <h5 class="text-uppercase font-800 mb-0">Sustainable Impact</h5>
                            <p>
                                Our programs focus on long-term solutions, empowering communities for lasting
                                change.
                            </p>
                        </div>
                    </div>
                    <div class="row mb-0">
                        <div class="col-3 text-center">
                            <a href="#" class="icon icon-xxl rounded-l bg-blue-dark"><i
                                    class="fa fa-hands-helping font-24"></i></a>
                        </div>
                        <div class="col-9">
                            <h5 class="text-uppercase font-800 mb-0">Transparent Operations</h5>
                            <p>
                                We're committed to accountability. Every donation is tracked and its impact
                                reported.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>

</template>

<script>

</script>

<style>
.bannerAbout {
    position: relative;
    width: 100%;
    height: 70px;
    background-image: url('/src/assets/images/pictures/backwhite.jpeg');
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    overflow: hidden;
}


.bannerAbout::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.banner-content {
    position: relative;
    z-index: 1;
    color: white;
    text-align: left;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

/* Footer styles remain unchanged */
.footer {
    background-color: #f8f9fa;
    padding: 30px 20px;
    text-align: center;
    border-top: 1px solid #e0e0e0;
}

.footer h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #333;
}

.footer p {
    margin-bottom: 15px;
    color: #666;
}

.heart {
    color: red;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 20px;
}

.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    text-decoration: none;
}

.facebook {
    background-color: #3b5998;
}

.twitter {
    background-color: #1da1f2;
}

.phone {
    background-color: #25D366;
}

.share {
    background-color: #ff4500;
}

.arrow-up {
    background-color: #6c757d;
}

.copyright {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 10px;
}

.footer-links a {
    color: #007bff;
    text-decoration: none;
    margin: 0 5px;
}
</style>