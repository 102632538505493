<template>
  <div>
    <div id="page">
      <div class="page-content header-clear-small">
        <div class="card card-style">
          <div class="content">
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">{{ error }}</div>
            <div v-else>
              <!-- Sub-sections -->
              <div v-for="(subSection, subIndex) in currentSection.sub_sections" :key="subSection.id"
                class="sub-section">
                <h5>{{ subIndex + 1 }}. {{ subSection.name }}</h5>
                <hr>

                <!-- Questions in sub-sections -->
                <div v-for="(subQuestion, subQuestionIndex) in subSection.questions" :key="subQuestion.id"
                  class="sub-question">
                  <h5 class="font-14">
                    <span class="question-number">{{ getQuestionNumber(subIndex, subQuestionIndex) }}.</span>
                    {{ subQuestion.question }}
                  </h5>

                  <!-- Handle Select Inputs for Sub-section -->
                  <div v-if="subQuestion.type === 'select'" class="input-style has-borders no-icon validate-field mb-4">
                    <select class="form-control" @change="setAnswer(surveyId, subQuestion.id, $event.target.value)">
                      <option v-for="option in subQuestion.question_options" :key="option.id" :value="option.value">
                        {{ option.option }}
                      </option>
                    </select>
                  </div>

                  <!-- Handle Number Inputs for Sub-section -->
                  <div v-else-if="subQuestion.type === 'number'"
                    class="input-style has-borders no-icon validate-field mb-4">
                    <input class="form-control" type="number" @input="setAnswer(surveyId, subQuestion.id, $event.target.value)" />
                  </div>

                  <!-- Handle Text Inputs for Sub-section -->
                  <div v-else-if="subQuestion.type === 'text'"
                    class="input-style has-borders no-icon validate-field mb-4">
                    <input class="form-control" type="text" @input="setAnswer(surveyId, subQuestion.id, $event.target.value)" />
                  </div>

                  <!-- Handle Long Text Inputs for Sub-section -->
                  <div v-else-if="subQuestion.type === 'longtext'"
                    class="input-style has-borders no-icon validate-field mb-4">
                    <textarea class="form-control" @input="setAnswer(surveyId, subQuestion.id, $event.target.value)"></textarea>
                  </div>
                </div>
              </div>

              <!-- Question Sections -->
              <div v-if="currentSection" class="section">
                <h5>{{ currentSection.name }}</h5>
                <hr>
                <div v-for="(question, index) in currentSection.questions" :key="question.id" class="question">
                  <h5 class="font-14">
                    <span class="question-number">{{ questionNumbers[index] }}.</span>
                    {{ question.question }}
                  </h5>

                  <!-- Handle Radio Inputs -->
                  <div v-if="question.type === 'radio'">
                    <div v-for="option in question.question_options" :key="option.id"
                      class="form-check interest-check scale-switch">
                      <input class="form-check-input" type="radio" :id="`${question.id}-${option.id}`"
                        :name="`question-${question.id}`" :value="option.value"
                        @change="setAnswer(surveyId, question.id, $event.target.value)" />
                      <label class="form-check-label rounded-m"
                        :class="{ 'highlight-selected': getAnswer(surveyId, question.id) === option.value }"
                        :for="`${question.id}-${option.id}`">{{ option.option }}</label>
                    </div>
                  </div>

                  <!-- Handle Select Inputs -->
                  <div v-else-if="question.type === 'select'"
                    class="input-style has-borders no-icon validate-field mb-4">
                    <select class="form-control validate-select" @change="setAnswer(surveyId, question.id, $event.target.value)">
                      <option value="" disabled selected>-- Select --</option>
                      <option v-for="option in question.question_options" :key="option.id" :value="option.value">
                        {{ option.option }}
                      </option>
                    </select>
                  </div>

                  <!-- Handle Checkbox Inputs -->
                  <div v-else-if="question.type === 'checkbox'">
                    <div v-for="option in question.question_options" :key="option.id"
                      class="form-check interest-check scale-switch">
                      <input class="form-check-input" type="checkbox" :id="`${question.id}-${option.id}`"
                        :value="option.value" @change="setAnswer(surveyId, question.id, $event.target.checked ? option.value : null)" />
                      <label class="form-check-label rounded-m" :for="`${question.id}-${option.id}`">
                        {{ option.option }}
                      </label>
                    </div>
                  </div>

                  <!-- Handle Number Inputs -->
                  <div v-else-if="question.type === 'number'"
                    class="input-style has-borders no-icon validate-field mb-4">
                    <input type="number" @input="setAnswer(surveyId, question.id, $event.target.value)" class="form-control"
                      :placeholder="question.question" />
                  </div>

                </div>
              </div>
              <hr>
              <a href="#" @click.prevent="handleSubmit"
                class="btn btn-full btn-m rounded-s shadow-l bg-highlight text-uppercase font-900 mb-4">
                അപേക്ഷ സമർപ്പിക്കുക
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { reactive } from 'vue';

export default {
  data() {
    return {
      formData: null,
      answers: reactive({}),
      loading: true,
      error: null,
    };
  },
  props: {
    surveyId: {
      type: String,
      required: true
    },
    sectionId: {
      type: Number,
      required: true
    }
  },
  computed: {
    currentSection() {
      return this.formData?.sections.find(section => section.id === this.sectionId);
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    questionNumbers() {
      return this.currentSection?.questions.map((_, index) => index + 1) || [];
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchFormData(this.surveyId);
    } else {
      this.error = 'Please log in to access this form';
      this.$router.push('/login');
    }
  },
  methods: {
    async fetchFormData(surveyId) {
      try {
        const response = await axios.get(`https://zakath.cloudocz.com/api/survey-forms/${surveyId}`, {
          headers: {
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        });
        this.formData = response.data;
        this.loading = false;
      } catch (err) {
        if (err.response && err.response.status === 401) {
          this.error = 'Your session has expired. Please log in again.';
          this.$store.dispatch('logout');
        } else {
          this.error = 'Error fetching form data';
        }
        this.loading = false;
      }
    },
    getQuestionNumber(subIndex, questionIndex) {
      const topLevelQuestionsCount = this.currentSection.questions ? this.currentSection.questions.length : 0;
      let previousSubSectionsQuestionsCount = 0;
      for (let i = 0; i < subIndex; i++) {
        previousSubSectionsQuestionsCount += this.currentSection.sub_sections[i].questions.length;
      }
      return topLevelQuestionsCount + previousSubSectionsQuestionsCount + questionIndex + 1;
    },
    setAnswer(surveyId, questionId, value) {
      if (!this.answers[surveyId]) {
        this.answers[surveyId] = {};
      }
      this.answers[surveyId][questionId] = value;
    },
    getAnswer(surveyId, questionId) {
      return this.answers[surveyId]?.[questionId];
    },
    async handleSubmit() {
      try {
        const payload = {
          survey_id: parseInt(this.surveyId, 10),
          section_id: this.sectionId,
          answers: this.answers[this.surveyId] || {}
        };
        console.log('Payload being sent:', payload);
        
        const response = await axios.post(`https://zakath.cloudocz.com/api/survey-forms`, payload, {
          headers: {
            'Authorization': `Bearer ${this.$store.state.token}`
          }
        });

        console.log('Response received:', response.data);

        if (response.status === 200 || response.status === 201) {
          alert('Section submitted successfully!');
          const nextSectionId = this.sectionId + 1;
          if (nextSectionId <= 6) {
            this.$router.push(`/survey${String.fromCharCode(64 + nextSectionId)}`);
          } else {
            this.$router.push('/survey');
          }
        } else {
          console.warn('Unexpected response status:', response.status);
          alert('Form submitted, but there might be an issue. Please check and try again if necessary.');
        }
      } catch (err) {
        console.error('Error details:', err.response?.data || err.message);
        if (err.response) {
          console.error('Error response:', err.response.data);
          console.error('Error status:', err.response.status);

          if (err.response.status === 401) {
            this.error = 'Your session has expired. Please log in again.';
            this.$store.dispatch('logout');
          } else if (err.response.status === 404) {
            // Handle the "Question not found" error
            alert('Error: One or more questions could not be found. Please refresh the form and try again.');
          } else {
            alert(`Error submitting form: ${err.response.data.message || 'Unknown error occurred'}`);
          }
        } else if (err.request) {
          console.error('Error request:', err.request);
          alert('No response received from the server. Please check your connection and try again.');
        } else {
          console.error('Error message:', err.message);
          alert('An error occurred while submitting the form. Please try again.');
        }
      }
    },
  }
};
</script>

<style scoped>
.interest-check label {
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

.highlight-selected {
  background-color: #d1ecf1;
  border: 2px solid #0c5460;
  color: #0c5460;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.question-number {
  font-weight: bold;
  margin-right: 5px;
}
</style>