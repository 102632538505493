import axios from 'axios';
import { createStore } from 'vuex';
import router from '@/router';

const store = createStore({
  state: {
    token: localStorage.getItem('token') || null,
    user: null,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      const response = await axios.post('https://zakath.cloudocz.com/api/login', credentials);
      const token = response.data.token;
      localStorage.setItem('token', token);
      commit('SET_TOKEN', token);
    },
    async logout({ commit }) {
      localStorage.removeItem('token');
      commit('SET_TOKEN', null);
      commit('SET_USER', null);
      commit('LOGOUT_USER');
      router.push('/');
    },
  },
  getters: {
    isAuthenticated: state => !!state.token,
  },
});

export default store;
