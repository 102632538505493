<template>
  <div id="page">
    <div class="page-content header-clear-small">

      <div class="content">
        <div class="row mb-n2">
          <div class="col-4 pe-2">
            <div class="card card-style mx-0 mb-3">
              <div class="p-3">
                <h4 class="font-700 text-uppercase font-12 opacity-50 mt-n2">Pending</h4>
                <h1 class="font-700 font-34 color-red-dark  mb-0">29</h1>
                <i class="fa fa-arrow-right float-end mt-n3 opacity-20"></i>
              </div>
            </div>
          </div>
          <div class="col-4 ps-2 pe-2">
            <div class="card card-style mx-0 mb-3">
              <div class="p-3">
                <h4 class="font-700 text-uppercase font-12 opacity-50 mt-n2">Assigned</h4>
                <h1 class="font-700 font-34 color-blue-dark mb-0">15</h1>
                <i class="fa fa-arrow-right float-end mt-n3 opacity-20"></i>
              </div>
            </div>
          </div>
          <div class="col-4 ps-2">
            <div class="card card-style mx-0 mb-3">
              <div class="p-3">
                <h4 class="font-700 text-uppercase font-12 opacity-50 mt-n2">Compete</h4>
                <h1 class="font-700 font-34 color-green-dark mb-0">17</h1>
                <i class="fa fa-arrow-right float-end mt-n3 opacity-20"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-style">
        <div class="content mt-0 mb-0">
          <div class="list-group list-custom-large short-border check-visited">
            <router-link :to="{ path: '/appdetails' }">
              <i class="fa fa-check-circle  bg-green-dark rounded-m"></i>
              <span style="margin-top: 0px !important;">Application 01</span>
              <i class="fa fa-angle-right"></i>
            </router-link>

            <router-link :to="{ path: '/appdetails' }">
              <i class="fa fa-times bg-red-dark rounded-m"></i>
              <span style="margin-top: 0px !important;">Application 02</span>
              <i class="fa fa-angle-right"></i>
            </router-link>

            <router-link :to="{ path: '/appdetails' }">
              <i class="fa-exclamation bg-orange-dark rounded-m"></i>
              <span style="margin-top: 0px !important;">Application 03</span>
              <i class="fa fa-angle-right"></i>
            </router-link>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'SurveyListingPage',
}
</script>

<style>

</style>