<template>
  <div id="page">
    <div class="page-content header-clear-small">

      <div class="card card-style">
        <router-link :to="{ path: '/addapp' }"
        class="btn btn-full btn-m color-white rounded-s gradient-blue border-0 font-700 text-uppercase">
        Create Applicantion <i class="fa fa-plus ms-2"></i>
      </router-link>
      </div>


      <div class="card card-style">
        <div class="content mt-0 mb-0">
          <div class="list-group list-custom-large short-border check-visited">
            <a href="#">
              <i class="fa fa-check-circle  bg-green-dark rounded-m"></i>
              <span style="margin-top: 0px !important;">Application 01</span>
              <i class="fa fa-angle-right"></i>
            </a>

            <a href="#">
              <i class="fa fa-times bg-red-dark rounded-m"></i>
              <span style="margin-top: 0px !important;">Application 02</span>
              <i class="fa fa-angle-right"></i>
            </a>

            <a href="#">
              <i class="fa-exclamation bg-orange-dark rounded-m"></i>
              <span style="margin-top: 0px !important;">Application 03</span>
              <i class="fa fa-angle-right"></i>
            </a>
            
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationListingPage',
}
</script>


<style>
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.btn a {
  text-decoration: none;
  color: aliceblue;
}

.btn-add {
  background-color: #C94F57;
  color: white;
  border: none;
  border-radius: 10px;
}

.btn-add i {
  margin-right: 8px;
}


</style>