<template>
  <div id="page">
    <div class="page-content header-clear-small">
      <h1 class="signup-title">Register</h1>
      <form @submit.prevent="handleSignUp">
        <!-- Name -->
        <div class="input-row">
          <div class="input-group full-width">
            <label for="name">Name</label>
            <input type="text" id="name" v-model="formData.name" placeholder="Enter your name" />
          </div>
          <div class="error" v-if="errors.name">{{ errors.name }}</div>
        </div>

        <!-- Username -->
        <div class="input-row">
          <div class="input-group full-width">
            <label for="username">Username</label>
            <input type="text" id="username" v-model="formData.user_name" placeholder="Enter your username" />
          </div>
          <div class="error" v-if="errors.user_name">{{ errors.user_name }}</div>
        </div>

        <!-- Email -->
        <div class="input-row">
          <div class="input-group full-width">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="formData.email" placeholder="Enter your email" />
          </div>
          <div class="error" v-if="errors.email">{{ errors.email }}</div>
        </div>

        <!-- Phone Number -->
        <div class="input-row">
          <div class="input-group full-width">
            <label for="phone">Phone Number</label>
            <input type="tel" id="phone" v-model="formData.phone_no" placeholder="Enter your phone number" />
          </div>
          <div class="error" v-if="errors.phone_no">{{ errors.phone_no }}</div>
        </div>

        <!-- Password -->
        <div class="input-row">
          <div class="input-group full-width">
            <label for="password">Password</label>
            <input type="password" id="password" v-model="formData.password" placeholder="Enter your password" />
          </div>
          <div class="error" v-if="errors.password">{{ errors.password }}</div>
        </div>

        <!-- Confirm Password -->
        <div class="input-row">
          <div class="input-group full-width">
            <label for="password_confirmation">Confirm Password</label>
            <input type="password" id="password_confirmation" v-model="formData.password_confirmation"
              placeholder="Confirm your password" />
          </div>
          <div class="error" v-if="errors.password_confirmation">{{ errors.password_confirmation }}</div>
        </div>

        <button type="submit" class="signup-button">Sign Up</button>

        <p class="login-link">Already have an account? <router-link :to="{ path: '/login' }">Login</router-link></p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      formData: {
        name: '',
        user_name: '',
        email: '',
        phone_no: '',
        password: '',
        password_confirmation: ''
      },
      errors: {}
    };
  },
  methods: {
    async handleSignUp() {
      this.errors = {};

      // Basic front-end validation
      for (const [key, value] of Object.entries(this.formData)) {
        if (!value) {
          this.errors[key] = `${key.replace('_', ' ')} is required`;
        }
      }
      if (this.formData.password !== this.formData.password_confirmation) {
        this.errors.password_confirmation = 'Passwords do not match';
      }

      if (Object.keys(this.errors).length > 0) {
        return;
      }

      try {
        const response = await axios.post('https://zakath.cloudocz.com/api/register', this.formData);
        console.log('Signup successful:', response.data);
        // Handle successful signup (e.g., show success message, redirect)
        this.$router.push('/login');
      } catch (error) {
        console.error('Signup error:', error);
        if (error.response && error.response.data && error.response.data.errors) {
          this.errors = error.response.data.errors;
        } else {
          this.errors.general = 'An error occurred during signup. Please try again.';
        }
      }
    }
  }
};
</script>

<style scoped>
.page-content {
  padding: 2rem;
  max-width: 500px;
  margin: auto;
}

.signup-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.input-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 48%;
}

.input-group {
  flex-grow: 1;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #555;
}

.input-group input,
.input-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.875rem;
  color: #333;
}

.input-group input:focus,
.input-group select:focus {
  outline: none;
  border-color: #007bff;
}

.custom-checkbox {
  display: none;
}

/* Custom checkbox container */
.custom-checkbox-container {
  display: flex;
  align-items: center;
  /* This centers items vertically */
  cursor: pointer;
  flex: 1;
}

/* Create a custom checkbox using a pseudo-element */
.checkbox-label::before {
  content: '';
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #5c5c5c;
  border-radius: 10px;
  /* For rounded corners */
  background-color: white;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  vertical-align: middle;
}

/* When the checkbox is checked, change the background color and add a checkmark */
.custom-checkbox:checked+.checkbox-label::before {
  background-color: #007bff;
  border-color: #007bff;
  position: relative;
}

/* Create a checkmark using another pseudo-element */
.custom-checkbox:checked+.checkbox-label::after {
  content: '\2714';
  /* Unicode checkmark */
  position: absolute;
  left: 4px;
  top: 2px;
  color: white;
  font-size: 14px;
}

/* Customize the label text */
.checkbox-label {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  user-select: none;
  vertical-align: middle;
  /* Ensures the label is vertically aligned with the custom checkbox */
}

.signup-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;
}

.signup-button:hover {
  background-color: #0056b3;
}

.login-link {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.875rem;
}

.login-link a {
  color: #007bff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

.error {
  color: red;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .signup-title {
    font-size: 1.5rem;
  }

  .input-group input,
  .input-group select {
    font-size: 0.75rem;
  }

  .signup-button {
    font-size: 0.875rem;
  }

  .login-link {
    font-size: 0.75rem;
  }

  .input-row {
    flex-direction: column;
  }

  .half-width {
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .signup-title {
    font-size: 1.75rem;
  }

  .input-group input,
  .input-group select {
    font-size: 0.8rem;
  }

  .signup-button {
    font-size: 0.9rem;
  }
}
</style>
