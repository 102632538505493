<template>

    <div id="page">
        <div class="page-content header-clear-small">
            <div class="card card-style preload-img" data-src="images/pictures/18.jpg" data-card-height="130">
                <div class="card-center ms-3">
                    <h1 class="color-white mb-0">Search</h1>
                    <p class="color-white mt-n1 mb-0">
                        It's a functional search system.
                    </p>
                </div>
                <div class="card-center me-3">
                    <a href="#" data-back-button
                        class="btn btn-m float-end rounded-xl shadow-xl text-uppercase font-800 bg-highlight">Back
                        Home</a>
                </div>
                <div class="card-overlay bg-black opacity-80"></div>
            </div>

            <div class="search-page">
                <div class="search-box search-header bg-theme card-style me-3 ms-3">
                    <i class="fa fa-search"></i>
                    <input type="text" class="border-0" placeholder="What are you looking for? " data-search />
                    <a href="#" class="clear-search disabled mt-0"><i class="fa fa-times color-red-dark"></i></a>
                </div>

                <div class="search-results disabled-search-list card card-style shadow-l">
                    <div class="content">
                        <div data-filter-item data-filter-name="all products eazy mobile" class="search-result-list">
                            <img class="shadow-l preload-img" src="images/empty.png" data-src="images/pictures/32s.jpg"
                                alt="img" />
                            <h1>Eazy | Mobile Website</h1>
                            <p>
                                Eazy Mobile, a best seller and trending item, loved by all.
                            </p>
                            <a href="#" class="bg-highlight">VIEW</a>
                        </div>

                        <div data-filter-item data-filter-name="all products eazy mobile" class="search-result-list">
                            <img class="shadow-l preload-img" src="images/empty.png" data-src="images/pictures/29s.jpg"
                                alt="img" />
                            <h1>Eazy | Cordova App</h1>
                            <p>
                                Eazy is also available as <br />
                                a Cordova & PhoneGap App.
                            </p>
                            <a href="#" class="bg-highlight">VIEW</a>
                        </div>

                        <div data-filter-item data-filter-name="all products mega mobile" class="search-result-list">
                            <img class="shadow-l preload-img" src="images/empty.png" data-src="images/pictures/27s.jpg"
                                alt="img" />
                            <h1>Mega | Mobile Website</h1>
                            <p>Mega Powerful, Mega Feature Filled, Easy to Use.</p>
                            <a href="#" class="bg-highlight">VIEW</a>
                        </div>

                        <div data-filter-item data-filter-name="all products mega mobile" class="search-result-list">
                            <img class="shadow-l preload-img" src="images/empty.png" data-src="images/pictures/28s.jpg"
                                alt="img" />
                            <h1>Mega | Cordova App</h1>
                            <p>
                                Mega is also available as <br />
                                a Cordova & PhoneGap App.
                            </p>
                            <a href="#" class="bg-highlight">VIEW</a>
                        </div>

                        <div data-filter-item data-filter-name="all products ultra mobile" class="search-result-list">
                            <img class="shadow-l preload-img" src="images/empty.png" data-src="images/pictures/26s.jpg"
                                alt="img" />
                            <h1>Ultra | Mobile Website</h1>
                            <p>
                                Ultra Powerful and Fast Mobile Website, an absolute best
                                seller.
                            </p>
                            <a href="#" class="bg-highlight">VIEW</a>
                        </div>

                        <div data-filter-item data-filter-name="all products ultra mobile" class="search-result-list">
                            <img class="shadow-l preload-img" src="images/empty.png" data-src="images/pictures/25s.jpg"
                                alt="img" />
                            <h1>Ultra | Cordova App</h1>
                            <p>
                                Ultra is also available as <br />
                                a Cordova & PhoneGap App.
                            </p>
                            <a href="#" class="bg-highlight">VIEW</a>
                        </div>

                        <div data-filter-item data-filter-name="all products kolor mobile" class="search-result-list">
                            <img class="shadow-l preload-img" src="images/empty.png" data-src="images/pictures/24s.jpg"
                                alt="img" />
                            <h1>Kolor | Mobile Website</h1>
                            <p>
                                Kolor is creative, beautiful, and offers beautiful colors.
                            </p>
                            <a href="#" class="bg-highlight">VIEW</a>
                        </div>

                        <div data-filter-item data-filter-name="all products kolor mobile" class="search-result-list">
                            <img class="shadow-l preload-img" src="images/empty.png" data-src="images/pictures/23s.jpg"
                                alt="img" />
                            <h1>Kolor | Cordova App</h1>
                            <p>
                                Kolor is also available as <br />
                                a Cordova & PhoneGap App.
                            </p>
                            <a href="#" class="bg-highlight">VIEW</a>
                        </div>

                        <div data-filter-item data-filter-name="all products vinga mobile" class="search-result-list">
                            <img class="shadow-l preload-img" src="images/empty.png" data-src="images/pictures/22s.jpg"
                                alt="img" />
                            <h1>Vinga | Mobile Website</h1>
                            <p>Simplicity and Elegance at it's best. Vinga is very fast.</p>
                            <a href="#" class="bg-highlight">VIEW</a>
                        </div>

                        <div data-filter-item data-filter-name="all products vinga mobile" class="search-result-list">
                            <img class="shadow-l preload-img" src="images/empty.png" data-src="images/pictures/21s.jpg"
                                alt="img" />
                            <h1>Vinga | Cordova App</h1>
                            <p>
                                Vinga is also available as <br />
                                a Cordova & PhoneGap App.
                            </p>
                            <a href="#" class="bg-highlight">VIEW</a>
                        </div>
                        <div class="search-no-results disabled">
                            <h3 class="bold top-10">Nothing found...</h3>
                            <span class="under-heading font-11 opacity-70 color-theme">There's nothing matching the
                                description you're looking for,
                                try a different keyword.</span>
                        </div>
                    </div>
                </div>

                <div class="search-trending card card-style">
                    <div class="content mb-2">
                        <h3>Trending Searches</h3>
                        <p class="font-11 mt-n2">What others are Searching for.</p>
                    </div>
                    <div class="list-group list-custom-small me-3 ms-3">
                        <a href="#">
                            <span class="font-400 color-blue-dark">All Products</span>
                            <i class="color-gray-dark fa fa-angle-right"></i>
                        </a>
                        <a href="#">
                            <span class="font-400 color-blue-dark">Eazy Mobile</span>
                            <i class="color-gray-dark fa fa-angle-right"></i>
                        </a>
                        <a href="#">
                            <span class="font-400 color-blue-dark">Mega Mobile</span>
                            <i class="color-gray-dark fa fa-angle-right"></i>
                        </a>
                        <a href="#">
                            <span class="font-400 color-blue-dark">Ultra Mobile</span>
                            <i class="color-gray-dark fa fa-angle-right"></i>
                        </a>
                        <a href="#">
                            <span class="font-400 color-blue-dark">Kolor Mobile</span>
                            <i class="color-gray-dark fa fa-angle-right"></i>
                        </a>
                        <a href="#" class="border-0">
                            <span class="font-400 color-blue-dark">Vinga Mobile</span>
                            <i class="color-gray-dark fa fa-angle-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="footer card card-style">
                <a href="#" class="footer-title"><span class="color-highlight">StickyMobile</span></a>
                <p class="footer-text">
                    <span>Made with
                        <i class="fa fa-heart color-highlight font-16 ps-2 pe-2"></i> by
                        Enabled</span><br /><br />Powered by the best Mobile Website Developer on Envato
                    Market. Elite Quality. Elite Products.
                </p>
                <div class="text-center mb-3">
                    <a href="#" class="icon icon-xs rounded-sm shadow-l me-1 bg-facebook"><i
                            class="fab fa-facebook-f"></i></a>
                    <a href="#" class="icon icon-xs rounded-sm shadow-l me-1 bg-twitter"><i
                            class="fab fa-twitter"></i></a>
                    <a href="#" class="icon icon-xs rounded-sm shadow-l me-1 bg-phone"><i class="fa fa-phone"></i></a>
                    <a href="#" data-menu="menu-share" class="icon icon-xs rounded-sm me-1 shadow-l bg-red-dark"><i
                            class="fa fa-share-alt"></i></a>
                    <a href="#" class="back-to-top icon icon-xs rounded-sm shadow-l bg-dark-light"><i
                            class="fa fa-angle-up"></i></a>
                </div>
                <p class="footer-copyright">
                    Copyright &copy; Enabled <span id="copyright-year">2017</span>. All
                    Rights Reserved.
                </p>
                <p class="footer-links">
                    <a href="#" class="color-highlight">Privacy Policy</a> |
                    <a href="#" class="color-highlight">Terms and Conditions</a> |
                    <a href="#" class="back-to-top color-highlight"> Back to Top</a>
                </p>
                <div class="clear"></div>
            </div>
        </div>
        <!-- End of Page Content-->
        <!-- All Menus, Action Sheets, Modals, Notifications, Toasts, Snackbars get Placed outside the <div class="page-content"> -->
        <div id="menu-settings" class="menu menu-box-bottom menu-box-detached">
            <div class="menu-title mt-0 pt-0">
                <h1>Settings</h1>
                <p class="color-highlight">Flexible and Easy to Use</p>
                <a href="#" class="close-menu"><i class="fa fa-times"></i></a>
            </div>
            <div class="divider divider-margins mb-n2"></div>
            <div class="content">
                <div class="list-group list-custom-small">
                    <a href="#" data-toggle-theme data-trigger-switch="switch-dark-mode" class="pb-2 ms-n1">
                        <i class="fa font-12 fa-moon rounded-s bg-highlight color-white me-3"></i>
                        <span>Dark Mode</span>
                        <div class="custom-control scale-switch ios-switch">
                            <input data-toggle-theme type="checkbox" class="ios-input" id="switch-dark-mode" />
                            <label class="custom-control-label" for="switch-dark-mode"></label>
                        </div>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </div>
                <div class="list-group list-custom-large">
                    <a data-menu="menu-highlights" href="#">
                        <i class="fa font-14 fa-tint bg-green-dark rounded-s"></i>
                        <span>Page Highlight</span>
                        <strong>16 Colors Highlights Included</strong>
                        <span class="badge bg-highlight color-white">HOT</span>
                        <i class="fa fa-angle-right"></i>
                    </a>
                    <a data-menu="menu-backgrounds" href="#" class="border-0">
                        <i class="fa font-14 fa-cog bg-blue-dark rounded-s"></i>
                        <span>Background Color</span>
                        <strong>10 Page Gradients Included</strong>
                        <span class="badge bg-highlight color-white">NEW</span>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
        </div>
        <!-- Menu Settings Highlights-->
        <div id="menu-highlights" class="menu menu-box-bottom menu-box-detached">
            <div class="menu-title">
                <h1>Highlights</h1>
                <p class="color-highlight">Any Element can have a Highlight Color</p>
                <a href="#" class="close-menu"><i class="fa fa-times"></i></a>
            </div>
            <div class="divider divider-margins mb-n2"></div>
            <div class="content">
                <div class="highlight-changer">
                    <a href="#" data-change-highlight="blue"><i class="fa fa-circle color-blue-dark"></i><span
                            class="color-blue-light">Default</span></a>
                    <a href="#" data-change-highlight="red"><i class="fa fa-circle color-red-dark"></i><span
                            class="color-red-light">Red</span></a>
                    <a href="#" data-change-highlight="orange"><i class="fa fa-circle color-orange-dark"></i><span
                            class="color-orange-light">Orange</span></a>
                    <a href="#" data-change-highlight="pink2"><i class="fa fa-circle color-pink2-dark"></i><span
                            class="color-pink-dark">Pink</span></a>
                    <a href="#" data-change-highlight="magenta"><i class="fa fa-circle color-magenta-dark"></i><span
                            class="color-magenta-light">Purple</span></a>
                    <a href="#" data-change-highlight="aqua"><i class="fa fa-circle color-aqua-dark"></i><span
                            class="color-aqua-light">Aqua</span></a>
                    <a href="#" data-change-highlight="teal"><i class="fa fa-circle color-teal-dark"></i><span
                            class="color-teal-light">Teal</span></a>
                    <a href="#" data-change-highlight="mint"><i class="fa fa-circle color-mint-dark"></i><span
                            class="color-mint-light">Mint</span></a>
                    <a href="#" data-change-highlight="green"><i class="fa fa-circle color-green-light"></i><span
                            class="color-green-light">Green</span></a>
                    <a href="#" data-change-highlight="grass"><i class="fa fa-circle color-green-dark"></i><span
                            class="color-green-dark">Grass</span></a>
                    <a href="#" data-change-highlight="sunny"><i class="fa fa-circle color-yellow-light"></i><span
                            class="color-yellow-light">Sunny</span></a>
                    <a href="#" data-change-highlight="yellow"><i class="fa fa-circle color-yellow-dark"></i><span
                            class="color-yellow-light">Goldish</span></a>
                    <a href="#" data-change-highlight="brown"><i class="fa fa-circle color-brown-dark"></i><span
                            class="color-brown-light">Wood</span></a>
                    <a href="#" data-change-highlight="night"><i class="fa fa-circle color-dark-dark"></i><span
                            class="color-dark-light">Night</span></a>
                    <a href="#" data-change-highlight="dark"><i class="fa fa-circle color-dark-light"></i><span
                            class="color-dark-light">Dark</span></a>
                    <div class="clearfix"></div>
                </div>
                <a href="#" data-menu="menu-settings"
                    class="mb-3 btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back
                    to Settings</a>
            </div>
        </div>
        <!-- Menu Settings Backgrounds-->
        <div id="menu-backgrounds" class="menu menu-box-bottom menu-box-detached">
            <div class="menu-title">
                <h1>Backgrounds</h1>
                <p class="color-highlight">Change Page Color Behind Content Boxes</p>
                <a href="#" class="close-menu"><i class="fa fa-times"></i></a>
            </div>
            <div class="divider divider-margins mb-n2"></div>
            <div class="content">
                <div class="background-changer">
                    <a href="#" data-change-background="default"><i class="bg-theme"></i><span
                            class="color-dark-dark">Default</span></a>
                    <a href="#" data-change-background="plum"><i class="body-plum"></i><span
                            class="color-plum-dark">Plum</span></a>
                    <a href="#" data-change-background="magenta"><i class="body-magenta"></i><span
                            class="color-dark-dark">Magenta</span></a>
                    <a href="#" data-change-background="dark"><i class="body-dark"></i><span
                            class="color-dark-dark">Dark</span></a>
                    <a href="#" data-change-background="violet"><i class="body-violet"></i><span
                            class="color-violet-dark">Violet</span></a>
                    <a href="#" data-change-background="red"><i class="body-red"></i><span
                            class="color-red-dark">Red</span></a>
                    <a href="#" data-change-background="green"><i class="body-green"></i><span
                            class="color-green-dark">Green</span></a>
                    <a href="#" data-change-background="sky"><i class="body-sky"></i><span
                            class="color-sky-dark">Sky</span></a>
                    <a href="#" data-change-background="orange"><i class="body-orange"></i><span
                            class="color-orange-dark">Orange</span></a>
                    <a href="#" data-change-background="yellow"><i class="body-yellow"></i><span
                            class="color-yellow-dark">Yellow</span></a>
                    <div class="clearfix"></div>
                </div>
                <a href="#" data-menu="menu-settings"
                    class="mb-3 btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">Back
                    to Settings</a>
            </div>
        </div>
        <!-- Menu Share -->
        <div id="menu-share" class="menu menu-box-bottom menu-box-detached">
            <div class="menu-title mt-n1">
                <h1>Share the Love</h1>
                <p class="color-highlight">
                    Just Tap the Social Icon. We'll add the Link
                </p>
                <a href="#" class="close-menu"><i class="fa fa-times"></i></a>
            </div>
            <div class="content mb-0">
                <div class="divider mb-0"></div>
                <div class="list-group list-custom-small list-icon-0">
                    <a href="auto_generated" class="shareToFacebook external-link">
                        <i class="font-18 fab fa-facebook-square color-facebook"></i>
                        <span class="font-13">Facebook</span>
                        <i class="fa fa-angle-right"></i>
                    </a>
                    <a href="auto_generated" class="shareToTwitter external-link">
                        <i class="font-18 fab fa-twitter-square color-twitter"></i>
                        <span class="font-13">Twitter</span>
                        <i class="fa fa-angle-right"></i>
                    </a>
                    <a href="auto_generated" class="shareToLinkedIn external-link">
                        <i class="font-18 fab fa-linkedin color-linkedin"></i>
                        <span class="font-13">LinkedIn</span>
                        <i class="fa fa-angle-right"></i>
                    </a>
                    <a href="auto_generated" class="shareToWhatsApp external-link">
                        <i class="font-18 fab fa-whatsapp-square color-whatsapp"></i>
                        <span class="font-13">WhatsApp</span>
                        <i class="fa fa-angle-right"></i>
                    </a>
                    <a href="auto_generated" class="shareToMail external-link border-0">
                        <i class="font-18 fa fa-envelope-square color-mail"></i>
                        <span class="font-13">Email</span>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SearchView',
};
</script>

<style></style>
