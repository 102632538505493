<template>

    <div id="page">

        <div class="page-content header-clear-small">

            <div style="padding-top:20px;" class="card card-style">
                <div class="content">
                    <h1 class="boxed-text-xl font-40">Full Name</h1>
                    <p class="text-center"><i class="fa fa-book color-red-dark pe-2"></i><span
                            class="opacity-50">Application Number</span></p>
                    <div class="row text-center mb-3 mx-n2">
                        <div class="col-4">
                            <i class="fas fa-wallet color-brown font-20"></i>
                            <h1 class="color-gray-dark font-20 font-400">125</h1>
                            <span class="d-block mt-n3">Amount</span>
                        </div>
                        <div class="col-4">
                            <i class="fa fa-star color-yellow-dark font-20"></i>
                            <h1 class="color-gray-dark font-20 font-400">110</h1>
                            <span class="d-block mt-n3">Points</span>
                        </div>
                        <div class="col-4">
                            <i class="fa fa-check-circle color-green-dark font-20"></i>
                            <h1 class="color-gray-dark font-20 font-400">Success</h1>
                            <span class="d-block mt-n3">Status</span>
                        </div>
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis blandit faucibus tortor. In a
                        vulputate mauris, quis luctus magna.
                    </p>
                    <a href="#" class="btn btn-m rounded-sm btn-full bg-highlight font-700 text-uppercase">Update
                        Details</a>
                </div>
            </div>


            <a data-bs-toggle="collapse" href="#category-1" aria-expanded="false" aria-controls="category-1"
                class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div class="align-self-center">
                            <h1 class="pe-3 font-40 font-900 opacity-30">01</h1>
                        </div>
                        <div class="align-self-center">
                            <h4>Applicant Information</h4>
                            <p class="opacity-80 font-10 mt-n2">Tap here to Expand</p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fa fa-angle-down color-gray-dark opacity-70 font-10 mt-1"></i><br>
                        </div>
                    </div>
                </div>
            </a>
            <div class="collapse" id="category-1">
                <!-- Recent Purchases -->
                <div class="card card-style">
                    <div class="content mb-4">
                        <h1>Name</h1>
                        <p class="mb-2">
                            Lorem ipsum dolor sit amet, lorem ipsum dolor ipsum dolor sit amet, consectetur elit.
                        </p>
                        <div class="list-group list-custom-large">
                            <a class="external-link" href="">
                                <i class="fa fa-user bg-user rounded-sm"></i>
                                <span>Name</span>
                                <strong>Abdullah</strong>
                            </a>
                            <a class="external-link" href="">
                                <i class="fa fa-phone rounded-sm"></i>
                                <span>Mobile Number</span>
                                <strong>+91 9072121314</strong>
                            </a>
                            <a href="#">
                                <i class="fa fa-home bg-home rounded-sm"></i>
                                <span>House Name</span>
                                <strong>name</strong>
                            </a>
                            <a href="#">
                                <i class="fa fa-briefcase bg-briefcase rounded-sm"></i>
                                <span>Job</span>
                                <strong>Software Developer</strong>
                            </a>
                            <a href="#" class="border-0">
                                <i class="fa fa-map-marker-alt bg-map rounded-sm"></i>
                                <span>Place</span>
                                <strong>Kerala</strong>
                            </a>
                        </div>

                    </div>
                </div>
            </div>


            <a data-bs-toggle="collapse" href="#category-2" aria-expanded="false" aria-controls="category-2"
                class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div class="align-self-center">
                            <h1 class="pe-3 font-40 font-900 opacity-30">02</h1>
                        </div>
                        <div class="align-self-center">
                            <h4>Applicant Details</h4>
                            <p class="opacity-80 font-10 mt-n2">Tap here to Expand</p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fa fa-angle-down color-gray-dark opacity-70 font-10 mt-1"></i><br>
                        </div>
                    </div>
                </div>
            </a>
            <div class="collapse" id="category-2">
                <div class="card card-style">
                    <div class="content mb-4">
                        <h1>Details</h1>
                        <p class="mb-2">
                            Lorem ipsum dolor sit amet, lorem ipsum dolor ipsum dolor sit amet, consectetur elit.
                        </p>
                        <div class="list-group list-custom-large">
                            <a class="external-link" href="">
                                <i class="fa fa-hand-holding-heart rounded-sm"></i>
                                <span class="mb-n1 font-12">JCC യുടെ സഹായം ലഭിക്കുന്നുണ്ടോ ?</span>
                                <strong class="mb-n1 font-9">ഉണ്ട്</strong>
                            </a>
                            <a class="external-link" href="">
                                <i class="fa fa-building rounded-sm"></i>
                                <span class="mb-n1 font-12">ഉണ്ട് എങ്കിൽ സഹായം ലഭിക്കുന്ന വിങ്.</span>
                                <strong class="mb-n1 font-9">ഫീൽഡ്</strong>
                            </a>
                            <a class="external-link" href="#">
                                <i class="fa fa-home rounded-sm"></i>
                                <span class="mb-n1 font-12">നിലവിൽ വീട്</span>
                                <strong class="mb-n1 font-9">വാടക വീട്ടിൽ</strong>
                            </a>
                            <a  href="#">
                                <i class="fa fa-medkit rounded-sm"></i>
                                <span class="mb-n1 font-12">സക്കാത്ത് സഹായത്തിന് </span>
                                <strong class="mb-n1 font-9">കാൻസർ,മറ്റു മാറാ രോഗ ചികിത്സ </strong>
                            </a>
                            <a href="#" class="external-link">
                                <i class="fa fa-file-pdf rounded-sm"></i>
                                <span class="mb-n1 font-12">ഡോക്യുമെന്റ് അപ്ലോഡ്</span>
                                <strong class="mb-n1 font-9">hiudhfskh.pdf</strong>
                            </a>
                        </div>

                    </div>
                </div>
            </div>

            <a data-bs-toggle="collapse" href="#category-3" aria-expanded="false" aria-controls="category-3"
                class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div class="align-self-center">
                            <h1 class="pe-3 font-40 font-900 opacity-30">03</h1>
                        </div>
                        <div class="align-self-center">
                            <h4>Survey</h4>
                            <p class="opacity-80 font-10 mt-n2">Tap here to Expand</p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fa fa-angle-down color-gray-dark opacity-70 font-10 mt-1"></i><br>
                        </div>
                    </div>
                </div>
            </a>
            <div class="collapse" id="category-3">
                <div class="card card-style">
                    <div class="content">
                        <div class="d-flex">
                            <div class="align-self-center">
                                <h2 class="mb-0">Survey Forms</h2>
                            </div>
                            <!-- <div class="align-self-center ms-auto">
                                <h6 class="mb-0 opacity-30 font-13">2hr, 25min</h6>
                            </div> -->
                        </div>

                        <div class="divider mt-3 mb-3"></div>

                        <a href="#" class="d-flex mb-3" id="form-1">
                            <div class="align-self-center">
                                <h1 class="pe-3 font-40 font-900 opacity-30">A</h1>
                            </div>
                            <div class="align-self-center">
                                <h4 class="mb-n1 font-12">പൊതുവിവരങ്ങൾ</h4>
                                <p class="mb-n1 font-8">(അപേക്ഷകരെയും ആശ്രിതരെയും സംബന്ധിച്ചത്)</p>
                            </div>
                            <div class="align-self-center ms-auto">
                                <router-link to="/surveyA">
                                    <i class="fa fa-play-circle fa-2x color-blue-dark"></i>
                                </router-link>
                            </div>
                        </a>
                        <hr>
                        <a href="#" class="d-flex mb-3">
                            <div class="align-self-center">
                                <h1 class="pe-3 font-40 font-900 opacity-30">B</h1>
                            </div>
                            <div class="align-self-center">
                                <h4 class="mb-n1 font-12">ജീവിത സൗകര്യങ്ങൾ</h4>
                                <p class="mb-n1 font-8">(അപേക്ഷകന്റെ)</p>
                            </div>
                            <div class="align-self-center ms-auto">
                                <router-link to="/surveyB">
                                    <i class="fa fa-play-circle fa-2x color-blue-dark"></i>
                                </router-link>
                            </div>
                        </a>
                        <hr>
                        <a href="#" class="d-flex mb-3">
                            <div class="align-self-center">
                                <h1 class="pe-3 font-40 font-900 opacity-30">C</h1>
                            </div>
                            <div class="align-self-center">
                                <h4 class="mb-n1 font-12">സാമ്പത്തിക വിവരങ്ങൾ</h4>
                                <p class="mb-n1 font-8">(അപേക്ഷകരെയും ആശ്രിതരുടെയും)</p>
                            </div>
                            <div class="align-self-center ms-auto">
                                <router-link to="/surveyC">
                                    <i class="fa fa-play-circle fa-2x color-blue-dark"></i>
                                </router-link>
                            </div>
                        </a>
                        <hr>
                        <a href="#" class="d-flex mb-3">
                            <div class="align-self-center">
                                <h1 class="pe-3 font-40 font-900 opacity-30">D</h1>
                            </div>
                            <div class="align-self-center">
                                <h4 class="mb-n1 font-12">വീടിന്റെ നിലവിലെ അവസ്ഥ</h4>
                                <p class="mb-n1 font-8">(അപേക്ഷകന്റെ)</p>
                            </div>
                            <div class="align-self-center ms-auto">
                                <router-link to="/surveyD">
                                    <i class="fa fa-play-circle fa-2x color-blue-dark"></i>
                                </router-link>
                            </div>
                        </a>
                        <hr>
                        <a href="#" class="d-flex mb-0">
                            <div class="align-self-center">
                                <h1 class="pe-3 font-40 font-900 opacity-30">E</h1>
                            </div>
                            <div class="align-self-center">
                                <h4 class="mb-n1 font-12"> സകാത്ത് സഹായം</h4>
                                <p class="mb-n1 font-8">(സഹായം ആവശ്യപ്പെട്ടത് സംബന്ധിച്ചത്)</p>
                            </div>
                            <div class="align-self-center ms-auto">
                                <router-link to="/surveyE">
                                    <i class="fa fa-play-circle fa-2x color-blue-dark"></i>
                                </router-link>
                            </div>
                        </a>
                        <hr>
                        <a href="#" class="d-flex mb-3">
                            <div class="align-self-center">
                                <h1 class="pe-3 font-40 font-900 opacity-30">F</h1>
                            </div>
                            <div class="align-self-center">
                                <h4 class="mb-n1 font-12">അപേക്ഷാ ഫോം</h4>
                                <p class="mb-n1 font-8"></p>
                            </div>
                            <div class="align-self-center ms-auto">
                                <router-link to="/surveyF">
                                    <i class="fa fa-play-circle fa-2x color-blue-dark"></i>
                                </router-link>
                            </div>
                        </a>
                        <hr>
                        <a href="#" class="d-flex mb-3">
                            <div class="align-self-center">
                                <h1 class="pe-3 font-40 font-900 opacity-30">G</h1>
                            </div>
                            <div class="align-self-center">
                                <h4 class="mb-n1 font-12">അഭിപ്രായം/ നിർദ്ദേശം</h4>
                                <p class="mb-n1 font-8">(നിർബന്ധമായും രേഖപ്പെടുത്തുക)</p>
                            </div>
                            <div class="align-self-center ms-auto">
                                <router-link to="/surveyG">
                                    <i class="fa fa-play-circle fa-2x color-blue-dark"></i>
                                </router-link>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script></script>

<style></style>