<template>
    <div id="page">
        <div class="page-content header-clear-small">
            <div class="card card-style">
                <div style="height: 200px !important;" class="banner">
                    <div class="banner-content">
                        <h2>Zakat</h2>
                        <p>Islamic Relief makes paying Zakat easy.</p>
                    </div>
                </div>
            </div>

            <router-link :to="{ path: '/profile' }" class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div class="align-self-center">
                            <h3>Complete my personal data</h3>
                            <p class="opacity-80 font-10 mt-n2">
                                Click here to fill your personal information
                            </p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fas fa-arrow-right color-gray-dark opacity-70 font-10 mt-1"></i><br />
                        </div>
                    </div>
                </div>
            </router-link>

            <a class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div class="align-self-center">
                            <h3>Applications process</h3>
                            <p class="opacity-80 font-10 mt-n2">
                                Click here to know about the application process
                            </p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fas fa-arrow-right color-gray-dark opacity-70 font-10 mt-1"></i><br />
                        </div>
                    </div>
                </div>
            </a>

            <router-link :to="{ path: '/aboutPage' }" class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div class="align-self-center">
                            <h3>About JCC</h3>
                            <p class="opacity-80 font-10 mt-n2">Know more about JCC</p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fas fa-arrow-right color-gray-dark opacity-70 font-10 mt-1"></i><br />
                        </div>
                    </div>
                </div>
            </router-link>

            <router-link :to="{ path: '/faqpage' }" class="no-effect card card-style mb-2">
                <div class="content">
                    <div class="d-flex mb-n1">
                        <div class="align-self-center">
                            <h3>FAQ</h3>
                            <p class="opacity-80 font-10 mt-n2">
                                If you have any problems, consult the FAQs on our website
                            </p>
                        </div>
                        <div class="ms-auto align-self-center text-center opacity-70">
                            <i class="fas fa-arrow-right color-gray-dark opacity-70 font-10 mt-1"></i><br />
                        </div>
                    </div>
                </div>
            </router-link>

            <div class="card card-style mt-4">
                <!-- News Banner -->
                <div class="news-card">
                    <div class="news-image"></div>
                    <div class="news-content">
                        <h3 class="news-title">
                            Supporting Our Community Through Adversity
                        </h3>
                        <p class="news-description">
                            Recent days have seen harrowing acts of racism and Islamophobia,
                            causing fear and distress in our communities.
                        </p>
                        <a href="#" class="news-link">Keep reading <span>&rarr;</span></a>
                    </div>
                </div>
            </div>

            <div class="card card-style">
                <footer class="footer">
                    <h4>JCC - JOIN COMMUNITY CARE</h4>
                    <div class="social-icons">
                        <a href="#" class="icon facebook"><i class="fab fa-facebook-f"></i></a>
                        <a href="#" class="icon twitter"><i class="fab fa-twitter"></i></a>
                        <a href="#" class="icon phone"><i class="fas fa-phone"></i></a>
                        <a href="#" class="icon share"><i class="fas fa-share-alt"></i></a>
                        <a href="#" class="icon arrow-up"><i class="fas fa-arrow-up"></i></a>
                    </div>
                    <p class="copyright">
                        Copyright © Enabled 2024. All Rights Reserved.
                    </p>
                    <div class="footer-links">
                        <a href="#">Privacy Policy</a> |
                        <a href="#">Terms and Conditions</a> |
                        <a href="#" class="back-to-top">Back to Top</a>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>

<script></script>

<style>
.banner {
    position: relative;
    width: 100%;
    background-image: url("/src/assets/images/pictures/zakat2.jpeg");
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    overflow: hidden;
}

.banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.banner-content {
    position: relative;
    z-index: 1;
    color: white;
    text-align: left;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.banner-content h2 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    color: aliceblue;
}

.banner-content p {
    font-size: 1.2rem;
    margin: 0;
    color: aliceblue;
}

/* News Banner Styles */

.news-card:hover {
    transform: scale(1.05);
}

.news-image {
    width: 100%;
    height: 150px;
    background-image: url("/src/assets/images/pictures/zakat.jpeg");
    /* Make sure to replace this with the correct image URL */
    background-size: cover;
    background-position: center;
}

.news-content {
    padding: 15px;
    background: #fff;
}

.news-title {
    font-size: 1.25rem;
    margin: 0 0 10px;
    color: #333;
    font-weight: bold;
}

.news-description {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 20px;
    /* Space between text and button */
}

.news-link {
    display: inline-flex;
    align-items: center;
    font-size: 0.9rem;
    color: #2e8b57;
    /* Green color similar to your example */
    text-decoration: none;
    margin-top: 10px;
    /* Space between text and link */
    font-weight: bold;
}

.news-link span {
    font-size: 1.2rem;
    margin-left: 5px;
    /* Gap between text and arrow */
}

.news-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .page-content {
        padding-bottom: 80px;
    }

   
    .banner-content h2 {
        font-size: 2rem;
    }

    .banner-content p {
        font-size: 1rem;
    }

    .custom-button {
        padding: 10px;
        gap: 15px;
    }

    .button-text h3 {
        font-size: 1rem;
        font-weight: 700;
    }

    .button-text p {
        margin: -5px 0 0;
        font-size: 0.7rem;
    }

    .footer h2 {
        font-size: 1.5rem;
    }

    .footer p {
        font-size: 0.9rem;
    }
}
</style>
