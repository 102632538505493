<template>
    <div id="footer-bar" class="footer-bar-1">
        <router-link to="/" :class="{ 'active-nav': isActive('/') }">
            <i class="fa fa-home"></i><span>Home</span>
        </router-link>

        <!-- Show extra tabs only if the user is authenticated -->
        <template v-if="isAuthenticated">
            <router-link to="/features" :class="{ 'active-nav': isActive('/features') }">
                <i class="fas fa-cogs"></i><span>Application</span>
            </router-link>
            <router-link to="/survey" :class="{ 'active-nav': isActive('/survey') }">
                <i class="fa fa-poll"></i><span>Survey</span>
            </router-link>
        </template>

        <!-- Conditionally render the Profile or Signup link based on authentication -->
        <router-link 
            :to="isAuthenticated ? '/profile' : '/signup'" 
            :class="{ 'active-nav': isActive(isAuthenticated ? '/profile' : '/signup') }"
        >
            <i class="fa fa-user"></i>
            <span>{{ isAuthenticated ? 'Profile' : 'Signup' }}</span>
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "AppFooter",
    computed: {
        ...mapGetters(['isAuthenticated'])
    },
    methods: {
        isActive(routePath) {
            return this.$route.path === routePath;
        }
    }
};
</script>

<style scoped>
.active-nav {
    color: #007bff;
    font-weight: bold;
}
.active-nav i {
    transform: scale(1.2);
}
</style>
