<template>
  <div class="page-content header-clear-medium">
    <h1 class="login-title">Login</h1>
    <form @submit.prevent="onLogin">
      <!-- General Error Message -->
      <div v-if="generalError" class="error general-error">
        {{ generalError }}
      </div>
      
      <!-- Email Field -->
      <div class="input-row">
        <div class="input-group full-width">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="email" placeholder="Enter your email" />
        </div>
        <div class="error" v-if="errors.email">{{ errors.email }}</div>
      </div>

      <!-- Password Field -->
      <div class="input-row">
        <div class="input-group full-width">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" placeholder="Enter your password" />
        </div>
        <div class="error" v-if="errors.password">{{ errors.password }}</div>
      </div>

      <!-- Login Button -->
      <button type="submit" :disabled="isLoading" class="login-button">
        <span v-if="isLoading">Logging in...</span>
        <span v-else>Login</span>
      </button>

      <!-- Signup Link -->
      <p class="signup-link">Don't have an account? <router-link :to="{ path: '/signup' }">Sign up</router-link></p>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      errors: {},
      generalError: null,
      isLoading: false
    };
  },
  methods: {
    ...mapActions(['login']),
    async onLogin() {
      this.errors = {};
      this.generalError = null;
      this.isLoading = true;

      // Basic front-end validation
      if (!this.email) this.errors.email = 'Email is required';
      if (!this.password) this.errors.password = 'Password is required';

      if (Object.keys(this.errors).length > 0) {
        this.isLoading = false;
        return;
      }

      try {
        await this.login({ email: this.email, password: this.password });
        this.$router.push('/homeview');
      } catch (error) {
        console.error('Login error:', error);
        if (error.response) {
          if (error.response.status === 401) {
            if (error.response.data.message === 'EMAIL_NOT_FOUND') {
              this.errors.email = 'No account found with this email address.';
            } else if (error.response.data.message === 'INVALID_PASSWORD') {
              this.errors.password = 'Incorrect password. Please try again.';
            } else {
              this.generalError = 'Invalid email or password. Please try again.';
            }
          } else if (error.response.data && error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            this.generalError = 'An error occurred during login. Please try again later.';
          }
        } else if (error.request) {
          this.generalError = 'Unable to reach the server. Please check your internet connection.';
        } else {
          this.generalError = 'An unexpected error occurred. Please try again.';
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>

.general-error {
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.error {
  color: #c62828;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.page-content {
  max-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 1rem;
}

.login-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.input-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.full-width {
  width: 100%;
}

.input-group {
  width: 100%;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #555;
}

.input-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
}

.input-group input:focus {
  outline: none;
  border-color: #007bff;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;
}

.login-button:hover {
  background-color: #0056b3;
}

.signup-link {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.875rem;
}

.signup-link a {
  color: #007bff;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

.error {
  color: red;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .login-title {
    font-size: 1.5rem;
  }

  .input-group input {
    font-size: 0.875rem;
  }

  .login-button {
    font-size: 0.875rem;
  }

  .signup-link {
    font-size: 0.75rem;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .login-title {
    font-size: 1.75rem;
  }

  .input-group input {
    font-size: 0.9rem;
  }

  .login-button {
    font-size: 0.9rem;
  }
}
</style>