<template>
  <div class="header header-fixed header-logo-center">
      <a href="#" class="header-title">JCC - JOIN COMMUNITY CARE</a>
      <a href="#" @click.prevent="goBack" class="back-button header-icon header-icon-1">
          <i class="fas fa-arrow-left"></i>
      </a>
      <!-- <template v-if="isAuthenticated">
          <a href="#" @click.prevent="handleUserIconClick" class="header-icon header-icon-4">
          </a>
      </template>
      <template v-else>
          <router-link :to="{ path: '/signup' }" class="header-icon header-icon-4">
              <i class="fas fa-user-plus"></i>
          </router-link>
      </template> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppHeader',
  computed: {
      ...mapGetters(['isAuthenticated'])
  },
  methods: {
      goBack() {
          this.$router.go(-1);  
      },
      handleUserIconClick() {
          console.log('User icon clicked');
      }
  }
}
</script>

<style scoped>
/* Custom styles */
</style>
