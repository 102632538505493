<template>
  <div>
    <div id="page">
      <div class="page-content header-clear-small">
        <div class="card card-style">
          <div class="content">
            <h3>അപേക്ഷാ ഫോം</h3>

            <div class="divider mb-3"></div>

            <h5 class="font-14">1. JCC യുടെ സഹായം ലഭിക്കുന്നുണ്ടോ?</h5>
            <div class="form-check interest-check scale-switch">
              <input class="form-check-input" type="radio" name="jcc_help" value="ഉണ്ട്" id="jcc_help_yes"
                v-model="selectedOption" />
              <label class="form-check-label rounded-m" :class="{ 'highlight-selected': selectedOption === 'ഉണ്ട്' }"
                for="jcc_help_yes">
                ഉണ്ട്
              </label>
            </div>

            <div class="form-check interest-check scale-switch">
              <input class="form-check-input" type="radio" name="jcc_help" value="ഇല്ല" id="jcc_help_no"
                v-model="selectedOption" />
              <label class="form-check-label rounded-m" :class="{ 'highlight-selected': selectedOption === 'ഇല്ല' }"
                for="jcc_help_no">
                ഇല്ല
              </label>
            </div>

            <div class="divider mb-3"></div>
            <h5 class="font-14">2. ഉണ്ട് എങ്കിൽ സഹായം ലഭിക്കുന്ന വിങ്.</h5>
            <div class="input-style has-borders no-icon validate-field mb-4">
              <input type="text" class="form-control validate-text" id="jcc_help_details"
                placeholder="സഹായത്തിന്റെ വിശദാംശങ്ങൾ">
            </div>

            <div class="divider mb-3"></div>
            <h5 class="font-14">3. നിലവിൽ വീട്</h5>
            <div class="input-style has-borders no-icon validate-field mb-4">
              <select class="form-control validate-select" id="current_house">
                <option value="default" disabled selected>-- തിരഞ്ഞെടുക്കുക --</option>
                <option value="സ്വാന്തം പേരിൽ">സ്വാന്തം പേരിൽ</option>
                <option value="കൂട്ടുകുടുംബം">കൂട്ടുകുടുംബം</option>
                <option value="വാടക വീട്ടിൽ">വാടക വീട്ടിൽ</option>
                <option value="സഹോദരന്റെ/സഹോദരിയുടെ വീട്ടിൽ">സഹോദരന്റെ/സഹോദരിയുടെ വീട്ടിൽ</option>
                <option value="പണയം">പണയം</option>
                <option value="മറ്റുള്ളവ">മറ്റുള്ളവ</option>
              </select>
            </div>
            <div class="input-style has-borders no-icon validate-field mb-4" id="other_house_details"
              style="display: none;">
              <input type="text" class="form-control validate-text" placeholder="മറ്റുള്ളവ വിശദമാക്കുക">
            </div>

            <div class="divider mb-3"></div>
            <h5 class="font-14">4. സക്കാത്ത് സഹായത്തിന് അപേക്ഷിക്കുന്നത്</h5>
            <div class="input-style has-borders no-icon validate-field mb-4">
              <select class="form-control validate-select" id="zakath_purpose">
                <option value="default" disabled selected>-- തിരഞ്ഞെടുക്കുക --</option>
                <option value="വീടുമായി ബന്ധപ്പെട്ട്">വീടുമായി ബന്ധപ്പെട്ട്</option>
                <option value="കിഡ്നി/ഹൃദയ, കാൻസർ, മറ്റു മാറാ രോഗ ചികിത്സ">കിഡ്നി/ഹൃദയ, കാൻസർ, മറ്റ്
                  മാറാ രോഗ ചികിത്സ</option>
                <option value="കട ബാധ്യത വീട്ടാൻ">കട ബാധ്യത വീട്ടാൻ</option>
                <option value="വിദ്യാഭ്യാസത്തിന്">വിദ്യാഭ്യാസത്തിന്</option>
                <option value="സ്വയം തൊഴിൽ">സ്വയം തൊഴിൽ</option>
                <option value="നിത്യ ചെലവിന്">നിത്യ ചെലവിന്</option>
              </select>
            </div>

            <div class="divider mb-3"></div>
            <h5 class="font-14">ഡോക്യുമെന്റ് അപ്ലോഡ്</h5>
            <div class="file-upload-wrapper mb-4">
              <input type="file" id="document-upload" class="file-upload-input" multiple accept="*" />
              <label for="document-upload" class="file-upload-label">
                <span class="upload-icon">📁</span>
                ഫയലുകൾ തിരഞ്ഞെടുക്കുക (Maximum 2)
              </label>
              <div id="file-list" class="mt-2"></div>
            </div>

            <!-- Submit Button -->
            <a href="#" class="btn btn-full btn-m rounded-s shadow-l bg-highlight text-uppercase font-900 mb-4">
              അപേക്ഷ സമർപ്പിക്കുക
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  mounted() {
    const fileInput = document.getElementById('document-upload');
    const fileList = document.getElementById('file-list');

    fileInput.addEventListener('change', function (event) {
      fileList.innerHTML = ''; // Clear the file list
      const files = event.target.files;

      // Limit the number of files to 2
      if (files.length > 2) {
        alert('You can only upload a maximum of 2 files.');
        fileInput.value = '';
      } else {
        Array.from(files).forEach((file) => {
          const fileItem = document.createElement('div');
          fileItem.textContent = file.name;
          fileList.appendChild(fileItem);
        });
      }
    });
  },
  data() {
    return {
      selectedOption: '' // This stores the currently selected option
    };
  }
}
</script>

<style scoped>
.file-upload-wrapper {
  position: relative;
  width: 100%;
}

.file-upload-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-upload-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  border-radius: 8px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.upload-icon {
  margin-right: 10px;
  font-size: 1.5em;
}

#file-list {
  max-height: 100px;
  overflow-y: auto;
}

#file-list div {
  padding: 5px;
  border-bottom: 1px solid #eee;
}

.page-content {
  padding-bottom: 80px;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.interest-check label {
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

.highlight-selected {
  background-color: #d1ecf1;
  border: 2px solid #0c5460;
  color: #0c5460;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}
</style>